import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { injectIntl } from "gatsby-plugin-intl"
import { keyBy } from "lodash"

import Layout from "../components/layout"
import Seo from "../components/seo"
import TopBar from "../components/topbar"
import IconButton from "../components/icon-button"
import { goToUrl, localize, createTicket } from "../utils"

import {
  UilComments,
  UilSmile,
  UilSuitcase,
  UilPhone,
  UilEnvelopeEdit,
  UilLocationPoint,
  UilFacebookF,
  UilLinkedin,
  UilInstagram,
  UilWhatsapp,
  UilYoutube
} from "@iconscout/react-unicons"

const Contatti = ({ intl }) => {
  const data = useStaticQuery(graphql`
    query ContactsQuery {
      allDirectusBlock(filter: { page: { eq: "contatti" } }) {
        nodes {
          type
          data {
            body
            title
            lang
          }
        }
      }
      allDirectusVariable {
        nodes {
          constants {
            address
            fax
            iva
            mail
            name
            phone
            disclaimer
            social {
              facebook
              linkedin
              instagram
              whatsapp
              youtube
            }
          }
        }
      }
    }
  `)
  const constants = data.allDirectusVariable.nodes[0].constants
  const blocks = data.allDirectusBlock.nodes
  const strings = keyBy(
    blocks.map(blockData => localize(blockData, intl.locale)),
    "type"
  )

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")
  const [privacy, setPrivacy] = useState("")
  const [customer, setCustomer] = useState("")
  const [sending, setSending] = useState(false)
  const [status, setStatus] = useState("")

  const resetForm = () => {
    setName("")
    setEmail("")
    setMessage("")
    setSubject("")
    setSending("")
    setCustomer("")
    setPrivacy(false)
  }

  const submitForm = async () => {
    setSending(true)
    setStatus("")
    const payload = {
      email,
      name,
      subject,
      body: message,
      customer,
      language: intl.locale,
      privacy,
    }

    if (
      payload.email &&
      payload.name &&
      payload.subject &&
      payload.body &&
      payload.customer &&
      payload.privacy
    ) {
      const ticketMessage = await createTicket(payload)
      if (ticketMessage) {
        setStatus(intl.formatMessage({ id: "terms.form_received" }))
        resetForm()
      } else setStatus(intl.formatMessage({ id: "terms.form_error" }))
    } else setStatus(intl.formatMessage({ id: "terms.form_fields" }))
    setSending(false)
  }
  return (
    <Layout name="p-contacts">
      <Seo title={strings.header.title} />
      <TopBar title={strings.header.title} xsmall></TopBar>
      <div className="a-container">
        <div className="p-contacts__heading">
          <div className="p-contacts__title">
            <h4>{strings.title.title}</h4>
            <span>{strings.title.body}</span>
          </div>
          <div
            className="p-contacts__waiting"
            onClick={() => goToUrl(constants.social.facebook)}
          >
            <UilComments size="50"></UilComments>
            <UilComments
              size="80"
              className="p-contacts__waiting__icon"
            ></UilComments>
            <div className="p-contacts__waiting__message">
              <strong>{strings.mega_social.title}</strong>
              <span>{strings.mega_social.body}</span>
            </div>
          </div>
        </div>
      </div>
      <div className="a-container p-contacts__main">
        <div className="p-contacts__form">
          <div className="p-contacts__form__field p-contacts__form__field--radio">
            <div
              className="p-contacts__form__field__radio__wrapper"
              onClick={() => setCustomer("Cliente privato")}
            >
              <input type="radio" name="who" />
              <div className="p-contacts__form__field__radio">
                <UilSmile size="40"></UilSmile>
                <span>
                  {intl.formatMessage({ id: "terms.form_individual" })}
                </span>
              </div>
            </div>

            <div
              className="p-contacts__form__field__radio__wrapper"
              onClick={() => setCustomer("Azienda")}
            >
              <input type="radio" name="who" />
              <div className="p-contacts__form__field__radio">
                <UilSuitcase size="40"></UilSuitcase>
                <span>{intl.formatMessage({ id: "terms.form_company" })}</span>
              </div>
            </div>
          </div>

          <div className="p-contacts__form__field">
            <input
              className="p-contacts__form__input"
              type="text"
              name="name"
              placeholder={intl.formatMessage({ id: "terms.name" })}
              value={name}
              onChange={evt => setName(evt.target.value)}
              required=""
            />
          </div>

          <div className="p-contacts__form__field">
            <input
              className="p-contacts__form__input"
              type="text"
              name="email"
              placeholder={intl.formatMessage({ id: "terms.mail" })}
              value={email}
              onChange={evt => setEmail(evt.target.value)}
              required=""
            />
          </div>

          <div className="p-contacts__form__field">
            <input
              className="p-contacts__form__input"
              type="text"
              name="subject"
              value={subject}
              onChange={evt => setSubject(evt.target.value)}
              placeholder={intl.formatMessage({ id: "terms.subject" })}
              required=""
            />
          </div>

          <div className="p-contacts__form__field">
            <textarea
              className="p-contacts__form__input p-contacts__form__input--message"
              style={{ height: 200, resize: "vertical" }}
              type="text"
              name="message"
              value={message}
              onChange={evt => setMessage(evt.target.value)}
              placeholder={intl.formatMessage({ id: "terms.message" })}
              required=""
            />
          </div>
          <div className="p-contacts__form__field p-contacts__form__field--radio">
            <input
              className="p-contacts__form__input"
              type="checkbox"
              id="privacy"
              name="privacy"
              style={{ padding: 0 }}
              checked={privacy}
              onChange={evt => setPrivacy(evt.target.checked)}
            ></input>
            {intl.formatMessage({ id: "terms.read_privacy" })}
            &nbsp;
            <a
              href="/privacy.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              {intl.formatMessage({ id: "terms.privacy_policy" })}
            </a>
          </div>
          <div
            className={`a-btn p-contacts__form__button ${
              sending ? "p-contacts__form__button--disabled" : ""
            }`}
            onClick={submitForm}
          >
            {sending
              ? intl.formatMessage({ id: "terms.form_sending" })
              : intl.formatMessage({ id: "terms.send_message" })}
          </div>

          {status !== "" && (
            <div className="p-contacts__form__message">{status}</div>
          )}
        </div>

        <div className="p-contacts__infos">
          <div className="p-contacts__infos__box">
            <h5>{intl.formatMessage({ id: "terms.contacts" })}</h5>
            <span>
              <UilPhone size="16"></UilPhone>
              {constants.phone}
            </span>
            <span>
              <UilEnvelopeEdit size="16"></UilEnvelopeEdit>
              {constants.mail}
            </span>
          </div>
          <div className="p-contacts__infos__box">
            <h5>{intl.formatMessage({ id: "terms.legal_address" })}</h5>
            <span>
              <UilLocationPoint size="16"></UilLocationPoint>
              {intl.formatMessage({ id: "company" })}
              <br />
              {constants.address}
            </span>
          </div>
          {/* <div className="p-contacts__infos__box">
            <h5>{intl.formatMessage({ id: "terms.social" })}</h5>
            <div className="p-contacts__infos__box__social__wrapper">
              {constants.social.facebook && (
                <div
                  className="p-contacts__infos__box__social p-contacts__infos__box__social--facebook"
                  onClick={() => goToUrl(constants.social.facebook)}
                >
                  <UilFacebookF></UilFacebookF>
                </div>
              )}
              {constants.social.linkedin && (
                <div
                  className="p-contacts__infos__box__social p-contacts__infos__box__social--linkedin"
                  onClick={() => goToUrl(constants.social.linkedin)}
                >
                  <UilLinkedin></UilLinkedin>
                </div>
              )}
              {constants.social.instagram && (
                <div
                  className="p-contacts__infos__box__social p-contacts__infos__box__social--instagram"
                  onClick={() => goToUrl(constants.social.instagram)}
                >
                  <UilInstagram></UilInstagram>
                </div>
              )}
              {constants.social.whatsapp && (
                <div
                  className="p-contacts__infos__box__social p-contacts__infos__box__social--whatsapp"
                  onClick={() => goToUrl(constants.social.whatsapp)}
                >
                  <UilWhatsapp></UilWhatsapp>
                </div>
              )}
              {constants.social.youtube && (
                <div
                  className="p-contacts__infos__box__social p-contacts__infos__box__social--youtube"
                  onClick={() => goToUrl(constants.social.youtube)}
                >
                  <UilYoutube></UilYoutube>
                </div>
              )}
            </div>
          </div> */}
        </div>

        <div className="p-contacts__boxes">
          <div className="p-contacts__box" style={{ cursor: "pointer" }}>
            <div className="p-contacts__box__item">
              <h5>{strings.jobs.title}</h5>
              <p>{strings.jobs.body}</p>
            </div>
            <div className="p-contacts__box__item">
              <a
                href={`mailto:${constants.mail}?subject=${strings.jobs.title}`}
              >
                <IconButton
                  icon="UilArrowRight"
                  size="26px"
                  className="a-btn--rounded"
                />
              </a>
            </div>
          </div>

          <div className="p-contacts__box">
            <div className="p-contacts__box__item">
              <h5>{strings.gabbiano.title}</h5>
              {strings.gabbiano.body.split("\n").map(line => (
                <p>{line}</p>
              ))}
            </div>
          </div>

          <div className="p-contacts__box">
            <div className="p-contacts__box__item">
              <h5>{strings.gabbiano_es.title}</h5>
              {strings.gabbiano_es.body.split("\n").map(line => (
                <p>{line}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default injectIntl(Contatti)
